<template>
  <div class="discuss-box">
    <div class="title">全部讨论（{{ total }}）</div>
    <div class="list" :style="{ 'min-height': `${boxHeight}px` }">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        :class="{ adopt: item.isAccepted && commentType == 4 }"
      >
        <div class="user-info">
          <el-image
            :src="
              item.authAvatarPath ||
                item.distributionUserAvatar ||
                item.customerAvatarPath
            "
          >
            <img src="@/assets/icon_touxiang.png" slot="error" />
          </el-image>
          <div class="info">
            <div class="name">
              {{
                item.customerAuthName ||
                  item.distributionUserName ||
                  item.customerName
              }}
              <el-tag
                size="mini"
                :type="authSlaveTypeMaps[item.authSlaveType].type"
                v-if="item.authSlaveType && item.authSlaveType != 4"
                >{{ authSlaveTypeMaps[item.authSlaveType].label }}</el-tag
              >
            </div>
            <p>
              {{ link([item.managerName, item.managerCompanyName], "，") }}
            </p>
          </div>
        </div>
        <div class="summary">
          {{ item.commentContent }}
        </div>
        <div class="footer">
          <div class="info">
            <span class="date">{{ item.inDateStr }}</span>
            <span
              v-if="
                item.customerSource || item.platformName || item.terminalSource
              "
            >
              {{
                link(
                  [item.platformName, terminalSourceMaps[item.terminalSource]],
                  "."
                )
              }}
            </span>
          </div>
          <div class="action">
            <el-button
              type="text"
              v-if="commentType == 4"
              @click="accepted(item.id, item.isAccepted)"
              >{{ item.isAccepted ? "取消采纳" : "采纳" }}</el-button
            >
            <el-button type="text" @click="deleteComment(item.id)"
              >删除</el-button
            >
          </div>
        </div>
        <div class="adopt-tag">
          <img src="@/assets/icon_yicaina.png" />
          <p>已采纳</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  trendsComment,
  deleteTrendsComment,
  trendsAccepted
} from "@/api/estate";
import { link } from "@/utils/index";
import { enumeration } from "@/config";
export default {
  props: {
    commentType: {
      // 1-活动，2-楼盘, 3-动态, 4-问答, 5-动态下的回复
      // 3：动态下的讨论，4:问答下的讨论，6：评论下的讨论
      type: Number,
      default: 3
    },
    show: {
      type: Boolean,
      default: false
    },
    bizId: {
      type: Number,
      default: null
    },
    count: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      total: 0,
      list: [],
      terminalSourceMaps: enumeration.terminalSourceMaps, // 终端来源：1-平台小程序  4-后台  7-IOS  8-Android
      authSlaveTypeMaps: enumeration.authSlaveTypeMaps // 认证类型：1-地产达人,2-置业顾问,3-PCN经纪
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(bool) {
        if (bool && this.bizId) {
          this.getTrendsComment();
        }
      }
    }
  },
  computed: {
    boxHeight() {
      // 根据条数，大概计算讨论框的高度，优化popover边缘显示问题
      let h = this.count * 150;
      return h > 480 ? 480 : h;
    }
  },
  methods: {
    link,
    async getTrendsComment() {
      // 获取楼盘动态下的评论
      const res = await trendsComment({
        bizId: this.bizId,
        commentType: this.commentType // 1-活动，2-楼盘, 3-动态, 4-问答, 5-动态下的回复
      });
      if (res) {
        this.total = res.total;
        this.list = res.list;
      }
    },
    async deleteComment(id) {
      // 删除评论
      const res = await deleteTrendsComment({ id });
      if (res) {
        this.$showSuccess("删除成功");
        this.$emit("refresh");
      }
    },
    async accepted(id, praiseStatus) {
      // ?bizId=采纳的回答ID & praiseStatus=0-取消采纳 1-采纳
      const res = await trendsAccepted({
        bizId: id,
        praiseStatus: praiseStatus ? 0 : 1
      });
      if (res) {
        this.getTrendsComment();
      }
    }
  }
};
</script>
<style scoped lang="scss">
.discuss-box {
  padding: 5px 0 0 0;
  .list {
    max-height: 480px;
    overflow-y: auto;
  }
  .title {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    padding: 0 10px 15px 10px;
  }
  .item {
    padding: 20px 10px;
    border-bottom: 1px solid #eee;
    position: relative;
    &:first-child:not(.adopt) {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: none;
    }
    &.adopt {
      background-color: #fff8f8;
      .adopt-tag {
        display: flex;
      }
    }
    .adopt-tag {
      position: absolute;
      right: 5px;
      top: 0;
      display: none;
      flex-direction: column;
      align-items: center;
      .el-image {
        width: 20px;
        margin: 0 auto;
      }
      p {
        font-size: 12px;
        color: #ed743f;
        text-align: center;
        line-height: 100%;
        margin-top: 5px;
      }
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    .el-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .info {
      flex: 1;
      margin-left: 10px;
    }
    .name {
      font-size: 14px;
      color: #333;
      line-height: 100%;
      display: flex;
      align-items: center;
    }
    p {
      font-size: 12px;
      color: #aaa;
      line-height: 100%;
      margin-top: 8px;
    }
    .el-tag {
      margin-left: 6px;
    }
    .tag {
      display: block;
      line-height: 18px;
      border-radius: 3px;
      padding: 0 5px;
      background-color: #fef1e4;
      color: #f79454;
      font-weight: normal;
      font-size: 12px;
      margin-left: 6px;
      &.blue {
        background-color: #ebf0ff;
        color: #3366ff;
      }
    }
  }
  .summary {
    font-size: 14px;
    color: #333;
    line-height: 180%;
    margin-top: 15px;
  }
  .tags {
    margin-top: 10px;
    font-size: 15px;
    color: #507dae;
    line-height: 160%;
    span {
      display: inline-block;
      white-space: nowrap;
      margin-right: 20px;
    }
  }
  .imgs {
    margin-top: 12px;
    .el-image {
      width: 110px;
      height: 90px;
      border-radius: 4px;
      margin-right: 10px;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .info {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #aaa;
      span {
        display: block;
        position: relative;
      }
      span + span {
        margin-left: 30px;
        &:before {
          display: block;
          content: "";
          width: 1px;
          height: 12px;
          background-color: #bbb;
          position: absolute;
          left: -15px;
          top: 50%;
          margin-top: -5px;
        }
      }
    }
    .el-button + .el-button {
      margin-left: 10px;
    }
    .el-button--text {
      padding: 0;
    }
  }
}
</style>
