var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "discuss-box"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("全部讨论（" + _vm._s(_vm.total) + "）")]), _c('div', {
    staticClass: "list",
    style: {
      'min-height': "".concat(_vm.boxHeight, "px")
    }
  }, _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      class: {
        adopt: item.isAccepted && _vm.commentType == 4
      }
    }, [_c('div', {
      staticClass: "user-info"
    }, [_c('el-image', {
      attrs: {
        "src": item.authAvatarPath || item.distributionUserAvatar || item.customerAvatarPath
      }
    }, [_c('img', {
      attrs: {
        "slot": "error",
        "src": require("@/assets/icon_touxiang.png")
      },
      slot: "error"
    })]), _c('div', {
      staticClass: "info"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(item.customerAuthName || item.distributionUserName || item.customerName) + " "), item.authSlaveType && item.authSlaveType != 4 ? _c('el-tag', {
      attrs: {
        "size": "mini",
        "type": _vm.authSlaveTypeMaps[item.authSlaveType].type
      }
    }, [_vm._v(_vm._s(_vm.authSlaveTypeMaps[item.authSlaveType].label))]) : _vm._e()], 1), _c('p', [_vm._v(" " + _vm._s(_vm.link([item.managerName, item.managerCompanyName], "，")) + " ")])])], 1), _c('div', {
      staticClass: "summary"
    }, [_vm._v(" " + _vm._s(item.commentContent) + " ")]), _c('div', {
      staticClass: "footer"
    }, [_c('div', {
      staticClass: "info"
    }, [_c('span', {
      staticClass: "date"
    }, [_vm._v(_vm._s(item.inDateStr))]), item.customerSource || item.platformName || item.terminalSource ? _c('span', [_vm._v(" " + _vm._s(_vm.link([item.platformName, _vm.terminalSourceMaps[item.terminalSource]], ".")) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "action"
    }, [_vm.commentType == 4 ? _c('el-button', {
      attrs: {
        "type": "text"
      },
      on: {
        "click": function click($event) {
          return _vm.accepted(item.id, item.isAccepted);
        }
      }
    }, [_vm._v(_vm._s(item.isAccepted ? "取消采纳" : "采纳"))]) : _vm._e(), _c('el-button', {
      attrs: {
        "type": "text"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteComment(item.id);
        }
      }
    }, [_vm._v("删除")])], 1)]), _vm._m(0, true)]);
  }), 0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "adopt-tag"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon_yicaina.png")
    }
  }), _c('p', [_vm._v("已采纳")])]);

}]

export { render, staticRenderFns }