<template>
  <el-dialog
    title="发布动态"
    v-bind="$attrs"
    v-on="$listeners"
    append-to-body
    width="785px"
    top="50px"
    :close-on-click-modal="false"
    @close="resetForm"
    class="f-dialog"
  >
    <el-form :model="formData" ref="form" label-position="top">
      <el-form-item
        label=""
        prop="content"
        :rules="[rules.required('请输入内容')]"
      >
        <el-input
          type="textarea"
          :rows="7"
          placeholder="请输入内容"
          v-model="formData.content"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="发布范围"
        prop="channels"
        :rules="[rules.required('请选择发布范围')]"
        class="channel-list"
      >
        <el-checkbox-group v-model="formData.channels">
          <el-checkbox label="all">全选</el-checkbox>
          <el-checkbox
            v-for="item in channelList"
            :label="item.id"
            :key="item.id"
            >{{ item.letTitle }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="上传图片" prop="attachList">
        <f-upload
          :type="3"
          accept="image/gif, image/jpeg, image/jpg, image/png, image/webp"
          :default-file-list="fileList"
          @on-success="uploadSuccess"
        ></f-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm">取 消</el-button>
      <el-button type="primary" @click="save">发 布</el-button>
    </span>
  </el-dialog>
</template>
<script>
let formData = {
  attachList: [], // 图片
  channels: ["all"], // 渠道ID集合
  fullChannel: true, // 是否全渠道
  content: "", // 动态内容
  customerSource: "4" // 渠道来源 4-后台
};
import FUpload from "@/components/upload";
import { getEnterpriseChannelStatus } from "@/api/distribution";
import { createTrends } from "@/api/estate";
import rules from "@/utils/rules";
export default {
  components: { FUpload },
  props: {},
  data() {
    return {
      rules,
      fileList: [],
      formData: this.$deepClone(formData),
      channelList: []
    };
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    },
    customerId() {
      return this.$store.state.userInfo.userId;
    }
  },
  watch: {
    "formData.channels": {
      handler(newVal, oldVal) {
        // 全渠道、指定渠道，勾选时互斥
        let all = "all";
        if (oldVal.length === 1 && oldVal[0] === all) {
          // “全渠道”处于选中状态，勾选其它渠道时，取消选中“全渠道”
          this.formData.channels = this.formData.channels.filter(
            v => v !== all
          );
        } else if (newVal.length > 1 && newVal.includes(all)) {
          // “全渠道”处于未选中状态，新勾选的项中包含“全渠道”，取消选中“其它状态”
          this.formData.channels = [all];
        }
      }
    }
  },
  created() {
    this.getEnterpriseChannelStatus();
  },
  methods: {
    async getEnterpriseChannelStatus() {
      // 获取渠道信息
      let query = { estateId: this.estateId };
      const data = await getEnterpriseChannelStatus(query);
      if (data) {
        this.channelList = data.channelVos;
      }
    },
    // 图片上传成功回调
    uploadSuccess(fileList) {
      this.fileList = fileList;
      this.formData.attachList = fileList.map(item => {
        return {
          fileUrl: item.url,
          name: item.name
        };
      });
    },
    // 保存
    save() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return;
        }
        let query = {
          ...this.formData,
          channels:
            this.formData.channels[0] == "all" ? [] : this.formData.channels, // 全选时，传空
          fullChannel: this.formData.channels[0] == "all",
          estateId: this.estateId,
          customerId: this.customerId
        };
        const res = await createTrends(query);
        if (res) {
          this.$showSuccess("提交成功");
          this.resetForm(); // 清空表单数据
          this.$emit("refresh");
        }
      });
    },
    // 重置表单
    resetForm() {
      this.fileList = [];
      this.$refs.form.resetFields(); // 清空表单数据
      this.$emit("update:visible", false);
    }
  }
};
</script>
<style scoped lang="scss">
.channel-list ::v-deep {
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-form-item__content {
    /*  max-height: 160px;
    overflow-y: auto; */
    line-height: 25px;
  }
}
</style>
