var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "title": "发布动态",
      "append-to-body": "",
      "width": "785px",
      "top": "50px",
      "close-on-click-modal": false
    },
    on: {
      "close": _vm.resetForm
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData,
      "label-position": "top"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "",
      "prop": "content",
      "rules": [_vm.rules.required('请输入内容')]
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 7,
      "placeholder": "请输入内容"
    },
    model: {
      value: _vm.formData.content,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "content", $$v);
      },
      expression: "formData.content"
    }
  })], 1), _c('el-form-item', {
    staticClass: "channel-list",
    attrs: {
      "label": "发布范围",
      "prop": "channels",
      "rules": [_vm.rules.required('请选择发布范围')]
    }
  }, [_c('el-checkbox-group', {
    model: {
      value: _vm.formData.channels,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "channels", $$v);
      },
      expression: "formData.channels"
    }
  }, [_c('el-checkbox', {
    attrs: {
      "label": "all"
    }
  }, [_vm._v("全选")]), _vm._l(_vm.channelList, function (item) {
    return _c('el-checkbox', {
      key: item.id,
      attrs: {
        "label": item.id
      }
    }, [_vm._v(_vm._s(item.letTitle))]);
  })], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "上传图片",
      "prop": "attachList"
    }
  }, [_c('f-upload', {
    attrs: {
      "type": 3,
      "accept": "image/gif, image/jpeg, image/jpg, image/png, image/webp",
      "default-file-list": _vm.fileList
    },
    on: {
      "on-success": _vm.uploadSuccess
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.resetForm
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("发 布")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }