var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "estate-comment"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_vm._m(0), _c('div', [_c('el-button', {
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus"
    },
    on: {
      "click": function click($event) {
        _vm.showEditDialog = true;
      }
    }
  }, [_vm._v("发布动态")])], 1)]), _c('div', {
    staticClass: "filter mt20"
  }, [_c('el-input', {
    staticClass: "f-search-bar",
    staticStyle: {
      "width": "320px"
    },
    attrs: {
      "placeholder": "动态关键词、发布者昵称"
    },
    model: {
      value: _vm.queryData.keywords,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "keywords", $$v);
      },
      expression: "queryData.keywords"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1)], 1), _vm.list.length ? _c('div', {
    staticClass: "list"
  }, _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item"
    }, [_c('div', {
      staticClass: "user-info"
    }, [_c('el-image', {
      attrs: {
        "src": item.customerAvatarPath || item.distributionUserAvatar || item.avatarPath
      }
    }, [_c('img', {
      attrs: {
        "slot": "error",
        "src": require("@/assets/icon_touxiang.png")
      },
      slot: "error"
    })]), _c('div', {
      staticClass: "info"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(item.customerAuthName || item.distributionUserName || item.customerName) + " "), item.authSlaveType && item.authSlaveType != 4 ? _c('el-tag', {
      attrs: {
        "size": "mini",
        "type": _vm.authSlaveTypeMaps[item.authSlaveType].type
      }
    }, [_vm._v(_vm._s(_vm.authSlaveTypeMaps[item.authSlaveType].label))]) : _vm._e()], 1), _c('p', [_vm._v(" " + _vm._s(_vm.link([item.managerName, item.managerCompanyName], "，")) + " ")])])], 1), _c('div', {
      staticClass: "summary",
      domProps: {
        "innerHTML": _vm._s(item.content.replace(/(\r|\n)+/g, '<br>'))
      }
    }), _c('div', {
      staticClass: "tags"
    }, _vm._l(item.tagList, function (tag, idx) {
      return _c('span', {
        key: idx
      }, [_vm._v("#" + _vm._s(tag.name) + "#")]);
    }), 0), _c('div', {
      staticClass: "imgs"
    }, _vm._l(item.attachList, function (d, idx) {
      return _c('el-image', {
        key: idx,
        attrs: {
          "src": d.fileUrl,
          "preview-src-list": item.attachList.length ? item.attachList.map(function (v) {
            return v.fileUrl;
          }) : []
        }
      });
    }), 1), _c('div', {
      staticClass: "footer"
    }, [_c('div', {
      staticClass: "info"
    }, [_c('span', {
      staticClass: "date"
    }, [_vm._v(_vm._s(item.publishTime))]), item.terminalSource == 4 ? _c('span', [_vm._v("房猫CMS ·WEB")]) : [item.customerSource || item.platformName || item.terminalSource ? _c('span', [_vm._v(" " + _vm._s(_vm.link([item.platformName, _vm.terminalSourceMaps[item.terminalSource]], ".")) + " ")]) : _vm._e()], item.official ? _c('span', [_vm._v("官方动态")]) : _vm._e(), item.fullChannel ? _c('span', [_vm._v("全渠道")]) : _vm._e(), !item.fullChannel && item.channelName.length ? _c('span', [_vm._v(_vm._s(item.channelName.join("、")))]) : _vm._e()], 2), _c('div', {
      staticClass: "action"
    }, [_c('el-popover', {
      ref: "popover".concat(index),
      refInFor: true,
      attrs: {
        "placement": "top-end",
        "width": "365"
      },
      model: {
        value: item.showDiscuss,
        callback: function callback($$v) {
          _vm.$set(item, "showDiscuss", $$v);
        },
        expression: "item.showDiscuss"
      }
    }, [_c('discuss', {
      attrs: {
        "count": item.commentCount,
        "bizId": item.trendsId,
        "show": item.showDiscuss,
        "commentType": 3
      },
      on: {
        "refresh": _vm.getList
      }
    })], 1), item.commentCount ? _c('el-button', {
      directives: [{
        name: "popover",
        rawName: "v-popover:[`popover${index}`]",
        arg: "popover".concat(index)
      }],
      attrs: {
        "type": "text"
      }
    }, [_vm._v("讨论(" + _vm._s(item.commentCount) + ")")]) : _vm._e(), _c('el-popconfirm', {
      staticClass: "ml20",
      attrs: {
        "title": "确定删除该评论？"
      },
      on: {
        "onConfirm": function onConfirm($event) {
          return _vm.deleteTrends(item.trendsId);
        }
      }
    }, [_c('el-button', {
      attrs: {
        "slot": "reference",
        "type": "text"
      },
      slot: "reference"
    }, [_vm._v("删除")])], 1)], 1)])]);
  }), 0) : _c('f-empty'), _c('el-pagination', {
    attrs: {
      "current-page": _vm.queryData.pageNum,
      "page-sizes": _vm.pageSizes,
      "page-size": _vm.queryData.pageSize,
      "layout": "sizes, prev, pager, next,jumper",
      "total": _vm.total
    },
    on: {
      "size-change": _vm.pageSizeChange,
      "current-change": _vm.pageNumChange,
      "update:currentPage": function updateCurrentPage($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:current-page": function updateCurrentPage($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      }
    }
  }), _c('edit', {
    attrs: {
      "visible": _vm.showEditDialog
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showEditDialog = $event;
      },
      "refresh": _vm.search
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("楼盘动态")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v(" 官方动态，由项目运营方相关角色发布的动态，包括不限于购房平台的“置业顾问”，分销平台的“营销号”，以及房猫CMS“管理员”； ")])]);

}]

export { render, staticRenderFns }