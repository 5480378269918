<template>
  <div class="estate-comment">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">楼盘动态</h3>
        <p class="summary">
          官方动态，由项目运营方相关角色发布的动态，包括不限于购房平台的“置业顾问”，分销平台的“营销号”，以及房猫CMS“管理员”；
        </p>
      </div>
      <div>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="showEditDialog = true"
          >发布动态</el-button
        >
      </div>
    </div>
    <div class="filter mt20">
      <el-input
        placeholder="动态关键词、发布者昵称"
        v-model="queryData.keywords"
        style="width: 320px;"
        class="f-search-bar"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
    </div>
    <!--list-->
    <div class="list" v-if="list.length">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="user-info">
          <el-image
            :src="
              item.customerAvatarPath ||
                item.distributionUserAvatar ||
                item.avatarPath
            "
          >
            <img src="@/assets/icon_touxiang.png" slot="error" />
          </el-image>
          <div class="info">
            <div class="name">
              {{
                item.customerAuthName ||
                  item.distributionUserName ||
                  item.customerName
              }}
              <el-tag
                size="mini"
                :type="authSlaveTypeMaps[item.authSlaveType].type"
                v-if="item.authSlaveType && item.authSlaveType != 4"
                >{{ authSlaveTypeMaps[item.authSlaveType].label }}</el-tag
              >
            </div>
            <p>
              {{ link([item.managerName, item.managerCompanyName], "，") }}
            </p>
          </div>
        </div>
        <div
          class="summary"
          v-html="item.content.replace(/(\r|\n)+/g, '<br>')"
        ></div>
        <div class="tags">
          <span v-for="(tag, idx) in item.tagList" :key="idx"
            >#{{ tag.name }}#</span
          >
        </div>
        <div class="imgs">
          <el-image
            v-for="(d, idx) in item.attachList"
            :key="idx"
            :src="d.fileUrl"
            :preview-src-list="
              item.attachList.length ? item.attachList.map(v => v.fileUrl) : []
            "
          ></el-image>
        </div>
        <div class="footer">
          <div class="info">
            <span class="date">{{ item.publishTime }}</span>
            <span v-if="item.terminalSource == 4">房猫CMS ·WEB</span>
            <template v-else>
              <span
                v-if="
                  item.customerSource ||
                    item.platformName ||
                    item.terminalSource
                "
              >
                {{
                  link(
                    [
                      item.platformName,
                      terminalSourceMaps[item.terminalSource]
                    ],
                    "."
                  )
                }}
              </span>
            </template>
            <span v-if="item.official">官方动态</span>
            <span v-if="item.fullChannel">全渠道</span>
            <span v-if="!item.fullChannel && item.channelName.length">{{
              item.channelName.join("、")
            }}</span>
          </div>
          <div class="action">
            <el-popover
              :ref="`popover${index}`"
              placement="top-end"
              width="365"
              v-model="item.showDiscuss"
            >
              <discuss
                :count="item.commentCount"
                :bizId="item.trendsId"
                :show="item.showDiscuss"
                :commentType="3"
                @refresh="getList"
              ></discuss>
            </el-popover>
            <el-button
              type="text"
              v-if="item.commentCount"
              v-popover:[`popover${index}`]
              >讨论({{ item.commentCount }})</el-button
            >

            <el-popconfirm
              title="确定删除该评论？"
              @onConfirm="deleteTrends(item.trendsId)"
              class="ml20"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </div>
        </div>
      </div>
    </div>
    <!--list end-->
    <f-empty v-else></f-empty>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageNumChange"
      :current-page.sync="queryData.pageNum"
      :page-sizes="pageSizes"
      :page-size="queryData.pageSize"
      layout="sizes, prev, pager, next,jumper"
      :total="total"
    >
    </el-pagination>
    <edit :visible.sync="showEditDialog" @refresh="search"></edit>
  </div>
</template>

<script>
import Edit from "./_components/edit";
import Discuss from "../_components/discuss";
import { trendsList, deleteTrends } from "@/api/estate";
import { link } from "@/utils/index";
import { enumeration } from "@/config";
export default {
  components: { Edit, Discuss },
  data() {
    return {
      terminalSourceMaps: enumeration.terminalSourceMaps, // 终端来源：1-平台小程序  4-后台  7-IOS  8-Android
      authSlaveTypeMaps: enumeration.authSlaveTypeMaps, // 认证类型：1-地产达人,2-置业顾问,3-PCN经纪
      showEditDialog: false, // 新增动态对话框
      pageSizes: [10, 20, 30, 50],
      total: 0,
      queryData: {
        trendsType: 4,
        keywords: "",
        pageNum: 1,
        pageSize: 10
      },
      list: []
    };
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    },
    userId() {
      return this.$store.state.userInfo.userId;
    }
  },
  created() {
    this.getList();
  },
  methods: {
    link,
    search() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    async getList() {
      // 动态列表
      const res = await trendsList({
        ...this.queryData,
        estateId: this.estateId,
        userId: this.userId
      });
      if (res) {
        this.list = res.list.map(item => {
          return {
            ...item,
            showDiscuss: false
          };
        });
      }
    },
    async deleteTrends(trendsId) {
      // 删除动态
      const res = deleteTrends({ trendsId });
      if (res) {
        this.$showSuccess("删除成功");
        this.getList();
      }
    },
    //页面条数
    pageSizeChange(val) {
      this.queryData.pageSize = val;
      this.getList();
    },
    //当前页面
    pageNumChange(val) {
      this.queryData.pageNum = val;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
.list {
  .item {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
  }
  .user-info {
    display: flex;
    align-items: center;
    .el-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .info {
      flex: 1;
      margin-left: 10px;
    }
    .name {
      font-size: 14px;
      color: #333;
      line-height: 100%;
      font-weight: bold;
      display: flex;
      align-items: center;
      .el-tag {
        margin-left: 10px;
      }
    }
    p {
      font-size: 12px;
      color: #aaa;
      line-height: 100%;
      margin-top: 8px;
    }
    .tag {
      display: block;
      line-height: 18px;
      border-radius: 3px;
      padding: 0 5px;
      background-color: #fef1e4;
      color: #f79454;
      font-weight: normal;
      font-size: 12px;
      margin-left: 6px;
      &.blue {
        background-color: #ebf0ff;
        color: #3366ff;
      }
    }
  }
  .summary {
    font-size: 15px;
    color: #333;
    line-height: 180%;
    margin-top: 15px;
  }
  .tags {
    margin-top: 10px;
    font-size: 15px;
    color: #507dae;
    line-height: 160%;
    span {
      display: inline-block;
      white-space: nowrap;
      margin-right: 20px;
    }
  }
  .imgs {
    margin-top: 12px;
    .el-image {
      width: 110px;
      height: 90px;
      border-radius: 4px;
      margin-right: 10px;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    .info {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #aaa;
      span {
        display: block;
        position: relative;
      }
      span + span {
        margin-left: 30px;
        &:before {
          display: block;
          content: "";
          width: 1px;
          height: 12px;
          background-color: #bbb;
          position: absolute;
          left: -15px;
          top: 50%;
          margin-top: -5px;
        }
      }
    }
    .el-button + .el-button {
      margin-left: 20px;
    }
    .el-button--text {
      padding: 0;
    }
  }
}
.estate-comment {
  padding: 20px 30px;
  min-height: 100%;
  background-color: #fff;
  box-shadow: rgba(228, 228, 228, 0.5) 0px 0px 10px;
  border-radius: 5px;
  .head {
    .title {
      h3 {
        font-size: 16px;
        line-break: 16px;
        padding-left: 20px;
        border-left: 3px solid $primary;
      }
      p {
        margin-top: 10px;
        padding-left: 20px;
        color: $color9;
      }
    }
  }
  .filter {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
}
</style>
